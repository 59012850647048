import React from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

const About: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      aboutPic: file(name: { eq: "l6crue72c9cqpd3qgkkm" }) {
        childCloudinaryAsset {
          fluid(maxWidth: 500) {
            ...CloudinaryAssetFluid
          }
        }
      }
    }
  `);

  return (
    <div id="about">
      <Img
        className="about__photo"
        fluid={data.aboutPic.childCloudinaryAsset.fluid}
      />

      <div className="about__text">
        <p>Hi, my name is Jordan.</p>
        <p>
          I believe in always learning and being curious. Inspired to build
          great products that solve problems while delighting users.
        </p>
      </div>

      <ul className="about__icons">
        <li>
          <span aria-label="fitness" role="img">
            🏋
          </span>{" "}
          Fitness Enthusiast
        </li>

        <li>
          <span aria-label="cyclist" role="img">
            🚴
          </span>{" "}
          Cyclist
        </li>
        <li>
          <span aria-label="foodie" role="img">
            😋
          </span>{" "}
          Foodie
        </li>
        <li>
          <span aria-label="bookworm" role="img">
            📖
          </span>{" "}
          Bookworm
        </li>
      </ul>
    </div>
  );
};

export default About;
